


























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import './scss/FiltersCheckboxes.scss';

@Component({
    name: 'FiltersCheckboxes',
    components: {
        BaseCheckbox: () => import('@components/BaseCheckbox/BaseCheckbox.vue'),
    }
})

export default class FiltersCheckboxes extends Vue {
    @Prop({}) ratio!: any[];
    @Prop({}) title!: string;
    @Prop({}) options!: any[];
    @Prop({}) selectedFilters!: any[];

    selected: string[] = [];

    mounted() {
        if (this.selectedFilters) {
            this.selected = this.selectedFilters;
        }
    }

    onSelectDocument(item) {
        if (this.selected.indexOf(item.label) === -1) {
            this.selected = this.selected.concat([item.label]);
        } else {
            this.selected.splice(this.selected.indexOf(item.label), 1);
        }
        this.$emit('change-selected', this.selected);
    }

    getTextForItem(val) {
        if (this.ratio && this.ratio.length) {
            return this.ratio.filter(item => item.value === val)[0].label;
        }
        return val;
    }

    resetSelected() {
        this.selected = [];
    }

    changeData(data) {
        if (data.section === this.title) {
            this.selected = JSON.parse(JSON.stringify(data.selected));
        }
    }
}
